<template>
	
</template>

<script>
	import request from '@/utils/request'
	export default {
		name: 'new_file',
		data() {
			this.$router.go(-1)
			return {}
		},
		
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	
</style>
